<template>
  <div class="product-list">
    <h1 class="title">Товары</h1>
    <div class="product-grid">
      <div v-for="(product, index) in products" :key="index" class="product-card">
        <img :src="product.image" alt="Товар" class="product-image" />
        <div class="product-info">
          <h2>{{ product.name }}</h2>
          <p>{{ product.price }} KZT</p>
        </div>

        <!-- Если товар уже добавлен в корзину, показываем кнопки управления -->
        <div v-if="cartItems[product.name]" class="cart-controls">
          <button @click="decreaseQuantity(product)" class="control-btn">
            <i class="fas fa-minus"></i>
          </button>
          <span class="quantity">{{ cartItems[product.name].quantity }}</span>
          <button @click="increaseQuantity(product)" class="control-btn">
            <i class="fas fa-plus"></i>
          </button>
          <button @click="removeFromCart(product)" class="btn-remove">
            <i class="fas fa-trash"></i>
          </button>
        </div>

        <!-- Если товара нет в корзине, показываем кнопку "Добавить" -->
        <button v-else @click="addToCart(product)" class="btn-add">Добавить в корзину</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['products', 'cartItems'],
  methods: {
    addToCart(product) {
      this.$emit('add-to-cart', product);
    },
    removeFromCart(product) {
      this.$emit('remove-from-cart', product);
    },
    increaseQuantity(product) {
      this.$emit('increase-quantity', product);
    },
    decreaseQuantity(product) {
      this.$emit('decrease-quantity', product);
    }
  }
};
</script>

<style scoped>
.product-list {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: left;
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

/* Сетка для товаров */
.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}

.product-card {
  flex: 1 1 calc(46% - 20px);
  background-color: #fff;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 15px;
}

.product-info h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.product-info p {
  font-size: 1rem;
  color: #888;
  margin-bottom: 10px;
}

/* Элементы управления */
.cart-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.quantity {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.control-btn {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.control-btn:hover {
  background-color: #ccc;
}

.btn-add {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-add:hover {
  background-color: #218838;
}

.btn-remove {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-remove:hover {
  background-color: #c82333;
}
</style>