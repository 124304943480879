<template>
  <div class="main-container">
    <ProductList
        :products="products"
        :cartItems="cartItems"
        @add-to-cart="addToCart"
        @remove-from-cart="removeFromCart"
        @increase-quantity="increaseQuantity"
        @decrease-quantity="decreaseQuantity"
    />

    <!-- Компонент для итоговой суммы и кнопки оплаты -->
    <Checkout :cartItems="cartItems" :total="total" />
  </div>
</template>

<script>
import ProductList from './ProductList.vue';
import Checkout from './CheckoutPage.vue';

export default {
  components: { ProductList, Checkout },
  data() {
    return {
      products : [
        {
          name: "Snickers",
          price: 50,
          image: "https://tse4.mm.bing.net/th?id=OIP.jmJe2VAT2kOAoshBR7xLuQHaDo&pid=Api"
        },
        {
          name: "Coca-Cola",
          price: 30,
          image: "https://tse4.mm.bing.net/th?id=OIP.JL11rt1Xx0D-e7cvmCkTpgHaHa&pid=Api"
        },
        {
          name: "Fanta",
          price: 30,
          image: "https://tse1.mm.bing.net/th?id=OIP.nh4gUVVI2uT0H4STJG0dEgHaHa&pid=Api"
        },
        {
          name: "Sprite",
          price: 30,
          image: "https://tse3.mm.bing.net/th?id=OIP.tCVj0AfJqOBfOcmOR0fuVAHaHa&pid=Api"
        },
        {
          name: "Orbit",
          price: 20,
          image: "https://tse1.mm.bing.net/th?id=OIP.QIfuio5Wrd7z4fd-3KCK6AHaHa&pid=Api"
        },
        {
          name: "Mars",
          price: 45,
          image: "https://tse3.mm.bing.net/th?id=OIP.97TNHdSGwXAeznG67WkkRAHaE_&pid=Api"
        },
        {
          name: "Twix",
          price: 50,
          image: "https://tse3.mm.bing.net/th?id=OIP.qEki2EeOuuGpW_LRpFs1WwHaHa&pid=Api"
        },
        {
          name: "Bounty",
          price: 45,
          image: "https://tse2.mm.bing.net/th?id=OIP.-2f5-J5tGX5fExpYLCdTXAHaD4&pid=Api"
        },
        {
          name: "KitKat",
          price: 40,
          image: "https://tse3.mm.bing.net/th?id=OIP.UU66nraBaDYkrH6BbtcOiwHaHa&pid=Api"
        },
        {
          name: "Pepsi",
          price: 30,
          image: "https://tse4.mm.bing.net/th?id=OIP.az73uQjCNOksdzG1vVLdSwHaHa&pid=Api"
        },
        {
          name: "Lays",
          price: 35,
          image: "https://tse1.mm.bing.net/th?id=OIP.asWIMrXnhPj5wTVqEonUtQHaHa&pid=Api"
        },
        {
          name: "Doritos",
          price: 40,
          image: "https://tse4.mm.bing.net/th?id=OIP.fZXz-myJmdDNyEtMUlixYAHaHa&pid=Api"
        },
        {
          name: "M&M's",
          price: 35,
          image: "https://tse2.mm.bing.net/th?id=OIP.JJdIzncKVUYWlLue2AeiwQHaHa&pid=Api"
        },
        {
          name: "Red Bull",
          price: 60,
          image: "https://tse1.mm.bing.net/th?id=OIP.MDR0lEd46Bf5_3PS3n6UGwHaHa&pid=Api"
        }
      ],
      cartItems: {}
    };
  },
  computed: {
    // Подсчитываем общую сумму товаров в корзине
    total() {
      return Object.values(this.cartItems).reduce((sum, item) => sum + (item.price * item.quantity), 0);
    }
  },
  methods: {
    addToCart(product) {
      if (!this.cartItems[product.name]) {
        this.cartItems = {
          ...this.cartItems,
          [product.name]: { ...product, quantity: 1 }
        };
      }
    },
    removeFromCart(product) {
      const rest = { ...this.cartItems };
      delete rest[product.name]; // Удаляем товар из корзины
      this.cartItems = rest; // Обновляем состояние корзины
    },
    increaseQuantity(product) {
      if (this.cartItems[product.name]) {
        this.cartItems[product.name].quantity++;
      }
    },
    decreaseQuantity(product) {
      if (this.cartItems[product.name] && this.cartItems[product.name].quantity > 1) {
        this.cartItems[product.name].quantity--;
      } else {
        this.removeFromCart(product);
      }
    }
  }
};
</script>

<style scoped>
.main-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

@media (max-width: 600px) {
  .main-container {
    padding: 10px;
  }
}
</style>