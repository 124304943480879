<template>
  <div class="checkout">

    <div class="checkout-bar" v-if="hasItemsInCart">
      <div class="total">
        Итого: <span class="total-price">{{ total }}</span> тенге
      </div>
      <button @click="handlePayment" class="pay-button">
        Оплатить
      </button>
    </div>

    <!-- Уведомления -->
    <div v-if="notificationMessage" :class="['notification', notificationStatus]" @animationend="resetNotification">
      {{ notificationMessage }}
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  props: ['cartItems', 'total'],
  data() {
    return {
      notificationMessage: null,
      notificationStatus: '', // success или failed
    };
  },
  computed: {
    hasItemsInCart() {
      return Object.keys(this.cartItems).length > 0;
    }
  },
  methods: {
    async handlePayment() {
      if (this.hasItemsInCart) {
        const transportId = uuidv4();
        try {
          await this.sendCartToBackend(transportId);
          this.sendToKaspi(transportId);
          this.showNotification('Оплата успешно отправлена!', 'success');
        } catch (error) {
          this.showNotification('Ошибка при оплате.' + error.message, 'failed');
        }
      }
    },

    async sendCartToBackend(transportId) {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/pay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transportId,
          cart: this.cartItems, // Отправляем корзину
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при отправке на сервер');
      }

      return response.json();
    },

    sendToKaspi(transportId) {
      if (typeof window.kaspi !== 'undefined') {
        window.kaspi.send("opay",
            JSON.stringify({"launchUrl": `https://opay.kaspi.kz/cpp/api/v1/start?transportId=${transportId}&type=Global`}), 'callbackFunc')
      } else {
        throw new Error('Сервис Kaspi недоступен.');
      }
    },
    showNotification(message, status) {
      this.notificationMessage = message;
      this.notificationStatus = status;
    },
    resetNotification() {
      this.notificationMessage = null;
      this.notificationStatus = '';
    }
  },
  mounted() {
    window.callbackFunc = (status) => {
      if (status === "SUCCESS") {
        this.$router.push({
          name: 'ThankYou',
          query: {
            status: 'SUCCESS',
            amount: this.total
          }
        });
      } else {
        this.showNotification('Ошибка при оплате.', 'failed');
      }
    };
  }
};
</script>

<style scoped>
.checkout-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(135deg, #f8f8f8, #e0e0e0);
  padding: 20px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  border-radius: 10px 10px 0 0;
}

.total {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.total-price {
  color: #4CAF50; /* Зеленый цвет для суммы */
}

.pay-button {
  background-color: #4CAF50;
  color: white;
  padding: 15px 40px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(76, 175, 80, 0.4);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-right: 30px;
}

.pay-button:hover {
  background-color: #45a049;
  box-shadow: 0 6px 20px rgba(76, 175, 80, 0.6);
}

.pay-button:disabled {
  background-color: rgb(128, 128, 128);
  cursor: not-allowed;
  box-shadow: none;
}
/* Уведомления */
.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  border-radius: 8px;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #28a745;
  animation: slideOut 0.5s ease-out 2s; /* 3s задержка перед началом slideOut */
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
}

/* Статус уведомлений */
.notification.success {
  background-color: #28a745;
}

.notification.failed {
  background-color: #dc3545;
}

/* Анимация появления и исчезновения уведомления */
</style>

