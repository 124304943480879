<template>
  <div class="thank-you-page">
    <div class="checkmark-container">
      <div class="checkmark">
        <div class="checkmark-circle"></div>
        <div class="checkmark-stem"></div>
        <div class="checkmark-kick"></div>
      </div>
    </div>

    <h1>Спасибо за покупку!</h1>
    <p>Вы успешно оплатили <span class="amount">{{ amount }} тенге</span></p>
    <button @click="goToHome" class="back-button">Перейти на главную страницу <br> Kaspi.kz</button>
  </div>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
      required: true
    }
  },
  methods: {
    goToHome() {
      window.location.href = 'https://main.kaspi.kz/'
    }
  }
}
</script>

<style scoped>
.thank-you-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f5f5f5;
  padding: 20px;
}

h1 {
  font-size: 2.5em;
  color: #4CAF50;
  margin-bottom: 10px;
}

.amount {
  font-weight: bold;
  font-size: 1.5em;
}

p {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 30px;
}

.checkmark-container {
  margin-bottom: 20px;
}

.checkmark {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;
}

.checkmark-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #4CAF50;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  animation: checkmark-circle-animation 0.5s ease-out;
}

.checkmark-stem {
  position: absolute;
  width: 10px;
  height: 50px;
  background-color: white;
  left: 60px;
  top: 20px;
  transform: rotate(45deg);
  z-index: 2;
  animation: checkmark-stem-animation 0.25s 0.5s ease-out forwards;
}

.checkmark-kick {
  position: absolute;
  width: 10px;
  height: 30px;
  background-color: white;
  left: 35px;
  top: 41px;
  transform: rotate(135deg);
  z-index: 2;
  animation: checkmark-kick-animation 0.25s 0.75s ease-out forwards;
}

@keyframes checkmark-circle-animation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checkmark-stem-animation {
  0% {
    height: 0;
  }
  100% {
    height: 50px;
  }
}

@keyframes checkmark-kick-animation {
  0% {
    height: 0;
  }
  100% {
    height: 30px;
  }
}

.back-button {
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(76, 175, 80, 0.4);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.back-button:hover {
  background-color: #45a049;
  box-shadow: 0 6px 20px rgba(76, 175, 80, 0.6);
}
</style>