import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/MainPage.vue';
import ThankYouPage from '../components/ThankYouPage.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/thank-you',
        name: 'ThankYou',
        component: ThankYouPage,
        props: (route) => ({
            status: route.query.status,
            transportId: route.query.transportId,
            amount: route.query.amount
        })
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;